* {
  box-sizing: border-box;
}
.hidden {
  display: none;
}
html,
body,
#root {
  overflow: hidden;
  margin: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}


/* 8th Wall Skinning */
/* Desktop redirect */
#almostthereContainer {
  background-image: url(../public/Bg8thWall.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.desktop-message, .desktop-hint {
  color: #464766 !important;
  background-color: white;
  padding: 5px 10px;
  border-radius: 10px;
}

.desktop-hint {
  margin:20px auto 0px !important;
  width:500px;
}

.desktop-home-link {
  color: inherit !important;
  background-color: inherit !important;
}

/* Mobile loading */
#loadBackground {
  background-image: url(../public/Bg8thWall.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

#requestingCameraPermissions {
  background-color: #1600DE !important;
}

.poweredby-img {
  bottom: 4.5% !important;
}